import React, { useEffect } from "react";

const SidebarWidget = () => {
  useEffect(() => {
    const widgetContainer = document.getElementById("sidebar-widget-js");

    const widgetScript = document.createElement("script");
    widgetScript.setAttribute("type", "text/javascript");
    widgetScript.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    widgetScript.async = true;
    widgetScript.innerHTML = `
      {
        "colorTheme": "light",
        "dateRange": "12M",
        "showChart": true,
        "locale": "en",
        "width": "100%",
        "height": "100%",
        "largeChartUrl": "",
        "isTransparent": true,
        "showSymbolLogo": true,
        "showFloatingTooltip": false,
        "plotLineColorGrowing": "rgba(0, 163, 18, 1)",
        "plotLineColorFalling": "rgba(0, 163, 18, 1)",
        "gridLineColor": "rgba(0, 163, 18, 0)",
        "scaleFontColor": "rgba(0, 163, 18, 1)",
        "belowLineFillColorGrowing": "rgba(0, 163, 18, 0.12)",
        "belowLineFillColorFalling": "rgba(0, 163, 18, 0.12)",
        "belowLineFillColorGrowingBottom": "rgba(0, 163, 18, 0)",
        "belowLineFillColorFallingBottom": "rgba(0, 163, 18, 0)",
        "symbolActiveColor": "rgba(0, 163, 18, 0.12)",
        "tabs": [
          {
            "title": "Indices",
            "symbols": [
              {
                "s": "FOREXCOM:SPXUSD",
                "d": "S&P 500"
              },
              {
                "s": "FOREXCOM:NSXUSD",
                "d": "US 100"
              },
              {
                "s": "FOREXCOM:DJI",
                "d": "Dow 30"
              },
              {
                "s": "INDEX:NKY",
                "d": "Nikkei 225"
              },
              {
                "s": "INDEX:DEU40",
                "d": "DAX Index"
              },
              {
                "s": "FOREXCOM:UKXGBP",
                "d": "UK 100"
              }
            ],
            "originalTitle": "Indices"
          }
        ]
      }
    `;
    widgetContainer.appendChild(widgetScript);
  }, []);

  return (
    <div className="sidebar-widget" id="sidebar-widget-js">
      {/* Code is appended inside here */}
    </div>
  );
};

export default SidebarWidget;
